import { useQuery } from "react-query";
import { apiListContracts } from "../../../../../../../api/contract/apiListContracts";
import { CONTRACT_STATE } from "../../../../../../../api/contract/contract.types";
import { TAuthenticatedUser } from "../../../../../../../reducers/Auth";
import { decorateContratosWithNumbers } from "../../../../../../../util/Contrato/util";
import { userIsRecruiter } from "../../../../../models/Staff/Staff";
import { TContractPartial } from "../../../types/contract.types";
import { contractKeys } from "../../../util/contract.query-keys";
import { TContractsPageFilters } from "./useContractsPageURLParams";

export default function useOffersQuery(
  contractsPageFilters: TContractsPageFilters,
  filtersReady: boolean,
  authUser: TAuthenticatedUser
) {
  return useQuery(
    contractKeys.listForContractsPage(contractsPageFilters),
    async () => {
      const params = {
        itemsPerPage: 10,
        partial: 1,
        page: contractsPageFilters.page,
      } as any;

      if (contractsPageFilters.publicar !== "") {
        if (contractsPageFilters.publicar === "null") {
          params["exists[publicar]"] = false;
          delete params.publicar;
        } else {
          params.publicar = contractsPageFilters.publicar;
        }
      } else {
        delete params.publicar;
      }
      if (contractsPageFilters.tipoDeTrato.length) {
        params.tipoDeTrato = contractsPageFilters.tipoDeTrato;
      } else {
        delete params.tipoDeTrato;
      }
      if (contractsPageFilters.origen.length) {
        params.origen = contractsPageFilters.origen;
      } else {
        delete params.origen;
      }
      if (contractsPageFilters.preset === "active") {
        params.estado = [CONTRACT_STATE.ACTIVE];
      } else {
        if (contractsPageFilters.estado.length) {
          params.estado = contractsPageFilters.estado;
        } else {
          delete params.estado;
        }
      }
      if (contractsPageFilters.provinciaOferta !== "") {
        params.provinciaOferta = contractsPageFilters.provinciaOferta;
      }
      if (contractsPageFilters.commercialDelegation && contractsPageFilters.commercialDelegation !== "") {
        params.commercialDelegation = contractsPageFilters.commercialDelegation;
      }
      if (contractsPageFilters.botLaunched === "yes") {
        params["numCommunicationIntents[gte]"] = 1;
      }
      if (contractsPageFilters.botLaunched === "no") {
        params["numCommunicationIntents[lt]"] = 1;
      }
      if (!params.owner && contractsPageFilters.owner !== "") {
        params.owner = contractsPageFilters.owner;
      }
      if (params.owner === "owner") {
        delete params.owner;
      }
      if (
        !params["service.owner"] &&
        contractsPageFilters["service.owner"] !== ""
      ) {
        params["service.owner"] = contractsPageFilters["service.owner"];
      }
      if (params["service.owner"] === "owner") {
        delete params["service.owner"];
      }
      if (contractsPageFilters.search !== "") {
        params.searchFor = contractsPageFilters.search;
      }
      if (
        contractsPageFilters.preset === "promote" ||
        contractsPageFilters.preset === "recruit"
      ) {
        params.phase = contractsPageFilters.preset;
      }
      if (contractsPageFilters.orden === "cvDate") {
        params["order[cvDate]"] = "asc";
      }
      if (contractsPageFilters.orden === "priority") {
        if (contractsPageFilters.preset === "active") {
          params["order[createdAt]"] = "desc";
        } else {
          if (userIsRecruiter(authUser)) {
            params["order[recruitPriority]"] = "desc";
          } else {
            params["order[priority]"] = "desc";
          }
        }
      }
      if (contractsPageFilters.orden === "newest") {
        params["order[createdAt]"] = "desc";
      }
      if (contractsPageFilters.orden === "oldest") {
        params["order[createdAt]"] = "asc";
      }

      const res = await apiListContracts(params);
      const contracts: TContractPartial[] = decorateContratosWithNumbers(
        res["hydra:member"]
      );
      return {
        contracts,
        totalItems: res["hydra:totalItems"],
      };
    },
    {
      enabled: filtersReady,
    }
  );
}
