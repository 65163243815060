import { push } from "connected-react-router";
import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import {
  applyOffer,
  fetchError,
  getCommercialDelegations,
  getLoggedUserDataSuccess,
  getStaffSuccess,
  hideBackdrop,
  modalShow,
  reportClientEvent,
  showAuthMessage,
  showBackdrop,
  showInscribeteHightlight,
  snackErrorMessage,
  snackSuccessMessage,
  userSetRefreshToken,
  userSetRememberMe,
  userSetToken,
  userSignInError,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpError,
  userSignUpSuccess,
} from "../actions";
import { apiCoreV3 } from "../apiCoreV3/apiCoreV3";
import {
  GET_LOGGED_USER_DATA,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
} from "../constants/ActionTypes";
import { queryClient } from "../lib/react-query";
import axios, { apiEmpleo } from "../util/ApiEmpleo";
import { validateEmail } from "../util/validators/yupValidators";

const signOutRequest = async () => {
  return undefined;
};

const signInUserPasswordGetTokenRequest = async (
  username: string,
  password: string
) => {
  let res = await axios.post("auth/login_check", {
    username: username,
    password: password,
  });
  if (res.data) {
    return res.data;
  }
};

const meUserRequest = async () => {
  let res = await axios.post("auth/me");
  if (res.data) {
    return res.data;
  }
};

const signInStaffRequest = async () => {
  let res = await axios.get(`staff`, {
    params: {
      isStaff: true,
      itemsPerPage: 500,
    },
  });
  if (res.data) {
    return res.data["hydra:member"];
  }
};

const signInCommercialDelegationsRequest = async () => {
  let res = await axios.get(`contratos/commercial-delegations`);
  if (res.data) {
    return res.data;
  }
};

const getDeferrerdApplies = (state: any) => {
  return state.auth.deferredApplies;
};

const getInitUrl = (state: any) => {
  return state.auth.initURL;
};

function* signInUserWithEmailPassword({ payload }: { payload: any }) {
  const { username, password, rememberMe } = payload;

  try {
    // @ts-ignore
    const authTokens = yield call(
      signInUserPasswordGetTokenRequest,
      username,
      password
    );
    const signInToken =
      authTokens && authTokens.token ? authTokens.token : null;
    const refreshToken =
      authTokens && authTokens.refresh_token ? authTokens.refresh_token : null;
    if (signInToken) {
      yield put(userSetRememberMe(rememberMe));
      yield put(userSetToken(signInToken, rememberMe));
      // @ts-ignore
      const authUser = yield call(meUserRequest);
      if (authUser) {
        yield put(getLoggedUserDataSuccess(authUser));
        yield put(userSignInSuccess());
        yield put(snackSuccessMessage("Te has loggeado satisfactoriamente"));
        if (authUser.userRoles.includes("ROLE_STAFF")) {
          // @ts-ignore
          let staff = yield call(signInStaffRequest);
          yield put(getStaffSuccess({ partialUsuarios: staff }));
          // @ts-ignore
          let commercialDelegations = yield call(signInCommercialDelegationsRequest);
          yield put(getCommercialDelegations({ params: commercialDelegations }))
        }

        // Register event in GA
        if ((window as any).gtag && process.env.REACT_APP_ANALYTICS_ID) {
          (window as any).gtag("event", "login", {
            event_category: "Cuidador",
          });
        }

        if (authUser.userRoles.includes("ROLE_EMPLOYEE")) {
          // It is possible to have deferred offer applies, so we proceed with them
          // @ts-ignore
          const deferredApplies = yield select(getDeferrerdApplies);
          // @ts-ignore
          const initUrl = yield select(getInitUrl);

          if (deferredApplies.length) {
            for (let i = 0; i < deferredApplies.length; i++) {
              // Must check if offer is already applied
              const alreadyApplied = authUser.candidato.valoraciones.find(
                (item: any) => item.contratoId === deferredApplies[i].id
              );
              if (!alreadyApplied) {
                yield put(applyOffer(deferredApplies[i]));
                yield put(
                  modalShow({
                    open: true,
                    type: "success",
                    message: "Te has inscrito en la oferta aplicada",
                    context: {
                      contratoTitle: deferredApplies[0].T_tulo,
                    },
                  })
                );
              } else {
              }
              // Reaload all offers
              // yield put(getOffers(authUser));

              if (initUrl) {
                yield put(push(initUrl));
              } else {
                yield put(push("/empleo/misOfertas/activas"));
              }
            }
          }

          if (initUrl) {
            yield put(push(initUrl));
          }
        }
      }
    } else {
      yield put(snackSuccessMessage("Ha habido un error al autenticarse"));
    }
    if (refreshToken) {
      yield put(userSetRefreshToken(refreshToken, rememberMe));
    }
  } catch (error) {
    // @ts-ignore
    if (error.response.status === 401) {
      if (validateEmail(username)) {
        yield put(
          userSignInError(
            `Este correo ${username} no está registrado, pruebe con su DNI.`
          )
        );
        yield put(snackErrorMessage("Correo no registrado, pruebe con su DNI"));
      } else {
        yield put(
          userSignInError(
            "Credenciales no válidas, pruebe con su DNI o Email si lo tiene registrado."
          )
        );
        yield put(snackErrorMessage("Error, las credenciales no son válidas"));
      }
    } else {
      // @ts-ignore
      yield put(userSignInError(error.message));
      yield put(snackErrorMessage("Se ha producido un error"));
    }
  }
}

function* signOut() {
  try {
    // @ts-ignore
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      yield put(userSetToken(null));
      yield put(userSetRefreshToken(null));
      yield put(userSignOutSuccess());
      yield put(snackSuccessMessage("Te has desloggeado correctamente"));

      // Register event in GA
      if ((window as any).gtag && process.env.REACT_APP_ANALYTICS_ID) {
        (window as any).gtag("event", "logout", {
          event_category: "Cuidador",
        });
      }

      // Clears all the caches
      queryClient.clear();
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getLoggedUserData({ updateStaff }: { updateStaff: boolean }) {
  try {
    // @ts-ignore
    const authUser = yield call(apiEmpleo.post, "auth/me");
    if (authUser) {
      yield put(getLoggedUserDataSuccess(authUser));
      if (updateStaff && authUser.userRoles.includes("ROLE_STAFF")) {
        // @ts-ignore
        let staff = yield call(signInStaffRequest);
        yield put(getStaffSuccess({ partialUsuarios: staff }));
      }
    }
  } catch (error) {
    yield put(
      fetchError("Ha habido un error al intentar actualizar datos de usuario")
    );
    // @ts-ignore
    if (error.response.status === 401) {
      // 401 Expired JWT Token
      yield put(userSetToken(null));
      axios.defaults.headers.common["Authorization"] = "";
      apiCoreV3.defaults.headers.common["Authorization"] = "";
      yield put(
        snackErrorMessage(
          "Tu autorización ha caducado. Puedes iniciar sesión de nuevo"
        )
      );
      return;
    }

    yield put(
      snackErrorMessage("Ha habido un error al intentar actualizar datos")
    );
    console.log("Error", error);
    // Posiblemente esta expirado o tenemos otro error
  }
}

const signUpUserRequest = async ({ payload }: { payload: any }) => {
  const userReg = {
    username: payload.DNI_NIE,
    password: payload.password,
    fullname: payload.Nombre + " " + payload.Apellidos,
    email: payload.Email,
    name: payload.Nombre,
    site: "JOBS",
    Nombre: payload.Nombre,
    Apellidos: payload.Apellidos,
    Apellidos_2: payload.Apellidos_2,
    Fecha_de_nacimiento: payload.Fecha_de_nacimiento,
    Lugar_de_nacimiento: payload.Lugar_de_nacimiento,
    Sexo: payload.Sexo,
    Carnet_de_conducir: payload.Carnet_de_conducir,
    Ciudad: payload.Ciudad?.value ?? "",
    Codigo_postal: payload.Codigo_postal,
    Phone: payload.Phone,
    Email: payload.Email,
    DNI_NIE: payload.DNI_NIE,
    Veh_culo_propio: payload.Veh_culo_propio,
    Nivel_formativo: payload.Nivel_formativo,
    A_os_de_experiencia: payload.A_os_de_experiencia,
    Direccion: payload.Direccion,
    Numero: payload.Numero,
    Piso: payload.Piso,
    Externa_entre_semana: payload.Externa_entre_semana,
    Externa_fin_de_semana: payload.Externa_fin_de_semana,
    Interna_entre_semana: payload.Interna_entre_semana,
    Interna_fin_de_semana: payload.Interna_fin_de_semana,
    R_gimen_de_trabajo: payload.R_gimen_de_trabajo,
    Acepto_las_condiciones_de_uso: payload.Acepto_las_condiciones_de_uso,
    Consiento_el_env_o_de_comunicaciones:
      payload.Consiento_el_env_o_de_comunicaciones,
    Otros_Idiomas: payload.Otros_Idiomas,
    Latitud: payload.Latitud,
    Longitud: payload.Longitud,
    Provincia: payload.Provincia,
    Titulaci_n_oficial_SAD: payload.Titulaci_n_oficial_SAD,
    Qu_tareas_quieres_o_puedes_realizar:
      payload.Qu_tareas_quieres_o_puedes_realizar,
    No_iniciar_bot_hasta: payload.No_iniciar_bot_hasta,
    Fuente: payload.Fuente,
    N_SS: payload.N_SS,
    Business_Country: "es",
    Franja_horaria: payload.Franja_horaria,
  };
  let res = await axios.post("employee_signup", userReg);
  if (res.data) {
    return res.data;
  }
  console.log("Respuesta error", res.status);
};

const avatarRequest = async ({ payload }: { payload: any }) => {
  const form = new FormData();
  const { avatar } = payload;
  if (avatar) {
    form.append("file", avatar);

    const response = await axios.post(
      `/candidatos/${payload.candidatoId}/avatar`,
      form,
      {
        headers: {
          "content-type": `multipart/form-data; boundary=${
            (form as any)._boundary
          }`,
        },
      }
    );

    return response;
  }
};

const cifObverseRequest = async ({ payload }: { payload: any }) => {
  const form = new FormData();
  const { cif_obverse } = payload;
  if (cif_obverse) {
    form.append("file", cif_obverse);
  }

  const response = await axios.post(
    `/candidatos/${payload.candidatoId}/obverse-cif`,
    form,
    {
      headers: {
        "content-type": `multipart/form-data; boundary=${
          (form as any)._boundary
        }`,
      },
    }
  );

  return response;
};

const cifBackRequest = async ({ payload }: { payload: any }) => {
  const form = new FormData();
  const { cif_back } = payload;
  if (cif_back) {
    form.append("file", cif_back);
  }

  const response = await axios.post(
    `/candidatos/${payload.candidatoId}/back-cif`,
    form,
    {
      headers: {
        "content-type": `multipart/form-data; boundary=${
          (form as any)._boundary
        }`,
      },
    }
  );

  return response;
};

const cvRequest = async ({ payload }: { payload: any }) => {
  const form = new FormData();
  const { cv } = payload;
  if (cv) {
    form.append("file", cv);
  }

  const response = await axios.post(
    `/candidatos/${payload.candidatoId}/curriculum`,
    form,
    {
      headers: {
        "content-type": `multipart/form-data; boundary=${
          (form as any)._boundary
        }`,
      },
    }
  );

  return response;
};

function* signUpUserCuidador(datosRegistro: any) {
  try {
    yield put(showBackdrop());
    // @ts-ignore
    const user = yield call(signUpUserRequest, datosRegistro);
    if (user && user.details && user.details.id) {
      datosRegistro.payload.candidatoId = user.details.id;

      // Seguimos loggeados como el usuario y registramos un Cuidador
      // @ts-ignore
      const authTokens = yield call(
        signInUserPasswordGetTokenRequest,
        datosRegistro.payload.DNI_NIE,
        datosRegistro.payload.password
      );
      if (authTokens) {
        const signInToken =
          authTokens && authTokens.token ? authTokens.token : null;
        const refreshToken =
          authTokens && authTokens.refresh_token
            ? authTokens.refresh_token
            : null;

        // User just registered. We need to use the new token
        localStorage.setItem("token", JSON.stringify(signInToken));
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + signInToken;
        apiCoreV3.defaults.headers.common["Authorization"] =
          "Bearer " + signInToken;
        yield put(userSetRememberMe(true));
        yield put(userSetToken(signInToken, true));

        if (datosRegistro.payload.avatar) {
          try {
            yield call(avatarRequest, datosRegistro);
          } catch (e) {
            let msg = "[ES-SignUp] - Error uploading Avatar file";
            msg += "\n  - EmployeeId: ";
            msg += datosRegistro.payload.candidatoId;
            msg += "\n  - FileName: ";
            msg += datosRegistro.payload.avatar.name
              ? datosRegistro.payload.avatar.name
              : "unknown";
            msg += "\n  - FileSize: ";
            msg += datosRegistro.payload.avatar.size
              ? datosRegistro.payload.avatar.size
              : "unknown";
            yield put(
              reportClientEvent({
                type: "client_js_error",
                clientEventSequenceNumber: 1,
                details: {
                  errorMsg: msg,
                  url: "",
                  lineNumber: window.location.href,
                },
              })
            );
          }
        }

        if (datosRegistro.payload.cif_obverse) {
          try {
            yield call(cifObverseRequest, datosRegistro);
          } catch (e) {
            let msg = "[ES-SignUp] - Error uploading CIF Obverse file";
            msg += "\n  - EmployeeId: ";
            msg += datosRegistro.payload.candidatoId;
            msg += "\n  - FileName: ";
            msg += datosRegistro.payload.cif_obverse.name
              ? datosRegistro.payload.cif_obverse.name
              : "unknown";
            msg += "\n  - FileSize: ";
            msg += datosRegistro.payload.cif_obverse.size
              ? datosRegistro.payload.cif_obverse.size
              : "unknown";
            msg += "\n  - Exception Message: ";
            // @ts-ignore
            msg += e.message;
            yield put(
              reportClientEvent({
                type: "client_js_error",
                clientEventSequenceNumber: 1,
                details: {
                  errorMsg: msg,
                  url: "",
                  lineNumber: window.location.href,
                },
              })
            );
          }
        }
        if (datosRegistro.payload.cif_back) {
          try {
            yield call(cifBackRequest, datosRegistro);
          } catch (e) {
            let msg = "[ES-SignUp] - Error uploading CIF Back file";
            msg += "\n  - EmployeeId: ";
            msg += datosRegistro.payload.candidatoId;
            msg += "\n  - FileName: ";
            msg += datosRegistro.payload.cif_back.name
              ? datosRegistro.payload.cif_back.name
              : "unknown";
            msg += "\n  - FileSize: ";
            msg += datosRegistro.payload.cif_back.size
              ? datosRegistro.payload.cif_back.size
              : "unknown";
            msg += "\n  - Exception Message: ";
            // @ts-ignore
            msg += e.message;
            yield put(
              reportClientEvent({
                type: "client_js_error",
                clientEventSequenceNumber: 1,
                details: {
                  errorMsg: msg,
                  url: "",
                  lineNumber: window.location.href,
                },
              })
            );
          }
        }

        if (datosRegistro.payload.cv) {
          try {
            yield call(cvRequest, datosRegistro);
          } catch (e) {
            let msg = "[ES-SignUp] - Error uploading Curriculum file";
            msg += "\n  - EmployeeId: ";
            msg += datosRegistro.payload.candidatoId;
            msg += "\n  - FileName: ";
            msg += datosRegistro.payload.cv.name
              ? datosRegistro.payload.cv.name
              : "unknown";
            msg += "\n  - FileSize: ";
            msg += datosRegistro.payload.cv.size
              ? datosRegistro.payload.cv.size
              : "unknown";
            msg += "\n  - Exception Message: ";
            // @ts-ignore
            msg += e.message;
            yield put(
              reportClientEvent({
                type: "client_js_error",
                clientEventSequenceNumber: 1,
                details: {
                  errorMsg: msg,
                  url: "",
                  lineNumber: window.location.href,
                },
              })
            );
          }
        }

        // Register event in GA
        if ((window as any).gtag && process.env.REACT_APP_ANALYTICS_ID) {
          (window as any).gtag("event", "Registro", {
            event_label: "Nuevo cuidador",
            event_category: "Cuidador",
          });
        }

        // @ts-ignore
        const authUser = yield call(meUserRequest);
        if (authUser) {
          yield put(userSignUpSuccess(authUser));
          yield put(showInscribeteHightlight());
        }
        if (refreshToken) {
          yield put(userSetRefreshToken(refreshToken, true));
        }

        // It is possible to have deferred offer applies, so we proceed with them
        // @ts-ignore
        const deferredApplies = yield select(getDeferrerdApplies);
        if (deferredApplies.length) {
          for (let i = 0; i < deferredApplies.length; i++) {
            yield put(applyOffer(deferredApplies[i]));
          }
          yield put(
            modalShow({
              open: true,
              type: "success",
              message: "Te has inscrito en la oferta aplicada",
              context: {
                contratoTitle: deferredApplies[0].T_tulo,
              },
            })
          );

          yield put(push("/empleo/misOfertas/activas"));
        }
        yield put(hideBackdrop());
      }
    }
  } catch (error: any) {
    yield put(hideBackdrop());
    let msg = "[ES-SignUp] - Error in SignUp Process";
    msg += "\n  - EmployeeId: ";
    msg += datosRegistro.payload.candidatoId;
    if (error instanceof Error) {
      if (error.message) {
        msg += "\n  - Message: " + error.message;
      }
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data["hydra:description"]
    ) {
      msg += "\n  - Description: " + error.response.data["hydra:description"];
    }
    yield put(
      reportClientEvent({
        type: "client_js_error",
        clientEventSequenceNumber: 1,
        details: {
          errorMsg: msg,
          url: "",
          lineNumber: window.location.href,
        },
      })
    );

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data["hydra:description"]
    ) {
      yield put(userSignUpError(error.response.data["hydra:description"]));
    }
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER as any, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* gluD() {
  yield takeEvery(GET_LOGGED_USER_DATA as any, getLoggedUserData);
}

export function* signUpUser() {
  yield takeEvery(SIGNUP_USER as string, signUpUserCuidador);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(gluD),
    fork(signUpUser),
  ]);
}
