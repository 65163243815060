import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { Fragment, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import {
  EVENT_HEARTBEAT,
  EVENT_LEAVE,
  EVENT_WINDOW_BLUR,
  EVENT_WINDOW_FOCUS,
  getCommercialDelegations,
  getFavoriteCandidatosSuccess,
  getJobTasks,
  getLoggedUserData,
  getLoggedUserDataSuccess,
  getStaffSuccess,
  initializeApp,
  initializeAppSuccess,
  reportClientEvent,
  setInitUrl,
  snackErrorMessage,
  updateCandidatos,
  updateWindowWidth,
  userSetRefreshToken,
  userSetToken,
} from "../../actions";
import { getOffersSuccess } from "../../actions/Empleo";
import { updateValoraciones } from "../../actions/Valoraciones";
import DelayedServiceReminder from "../../apps/affinity/components/DelayedServiceReminder";
import MainApp from "../../apps/index";
import Error404 from "../../components/Error404";
import Header from "../../components/Header";
import InfoView from "../../components/InfoView";
import SimpleHeader from "../../components/SimpleHeader";
import AppLocale from "../../lngProvider";
import { store } from "../../store";
import axios, { apiEmpleo } from "../../util/ApiEmpleo";
import { APP_START_PAGE } from "../../util/defaults/app";
import { reportPageView } from "../../util/reportPageView";
import AffinitySignIn from "../AffinitySignIn/AffinitySignIn";
import Footer from "../Footer";
import JobsResetPassword from "../JobsResetPassword";
import EmpleoSignIn from "../JobsSignIn";
import LangWrapper from "../LangWrapper";
import LoadingPage from "../LoadingPage";
import RegistroCuidador from "../CandidateSignUp";
import Sidebar from "../SideNav";
import cuideoTheme from "../themes/defaultTheme";
import ZohoOauth2Callback from "../ZohoOauth2Callback";
import { apiCoreV3 } from "../../apiCoreV3/apiCoreV3";
import { JOB_SIGN_IN } from "../../constants/routes";

const EmployeeProfilePage = React.lazy(
  () =>
    import("../../apps/jobsite/pages/EmployeeProfilePage/EmployeeProfilePage")
);
const SettingsPage = React.lazy(() => import("../../apps/settings/index"));

const StyledAppMain = styled.div<{ background: string }>`
  ${({ background }) => {
    if (background === "white") {
      return `
      background-color: ${cuideoTheme.palette.common.white};
      `;
    } else {
      return `
      background-color: #e4ebef;
      `;
    }
  }}
  transition: all 150ms ease-in-out;
  width: 100%;
  position: relative;
  z-index: 0;
`;

interface StyledAppWithoutSidebarWrapperProps {
  leftpadding?: number;
  topMargin?: number;
}
const StyledAppWithoutSidebarWrapper = styled.div<StyledAppWithoutSidebarWrapperProps>`
  padding-left: ${({ leftpadding }) => leftpadding}rem;
  min-height: 50vh;
`;

const AppWithoutHeaderWrapper = styled.div<StyledAppWithoutSidebarWrapperProps>`
  margin: 0 auto;
`;

const App = ({
  initURL,
  setInitUrl,
  getLoggedUserData,
  getLoggedUserDataSuccess,
  history,
  match,
  location,
  locale,
  authUser,
  appPaddingLeft,
  appMarginTop,
  getOffersSuccess,
  getStaffSuccess,
  background,
  header,
  updateWindowWidth,
  updateCandidatos,
  updateValoraciones,
  isInitialized,
  initializeAppSuccess,
  getFavoriteCandidatosSuccess,
}: {
  initURL: string;
  setInitUrl: Function;
  getLoggedUserData: Function;
  getLoggedUserDataSuccess: Function;
  history: any;
  match: any;
  location: any;
  locale: string;
  authUser: any;
  appPaddingLeft: number;
  appMarginTop: number;
  tasks: any;
  getJobTasks: Function;
  initializeApp: Function;
  getOffersSuccess: Function;
  getStaffSuccess: Function;
  background: string;
  header: any;
  updateWindowWidth: Function;
  updateCandidatos: Function;
  updateValoraciones: Function;
  isInitialized: boolean;
  initializeAppSuccess: Function;
  getFavoriteCandidatosSuccess: Function;
}) => {
  const languageCode = authUser?.userPreferredLanguage === "fr" ? "fr" : "es";
  const currentAppLocale = AppLocale[languageCode];

  useEffect(() => {
    const updateStaffData = async () => {
      const sRes = await apiEmpleo.get(`staff`, {
        params: {
          isStaff: true,
          itemsPerPage: 500,
        },
      });
      if (sRes && sRes["hydra:member"]) {
        getStaffSuccess({
          partialUsuarios: sRes["hydra:member"],
        });
      }
    };
    const updateCommercialDelegations = async () => {
      const res = await apiEmpleo.get(`contratos/commercial-delegations`);
      if (res) {
        store.dispatch(getCommercialDelegations({ params: res }));
      }
    }

    const initializeApp = async () => {
      let authUser: any = null;
      const token = store.getState().auth.token;
      if (token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        apiCoreV3.defaults.headers.common["Authorization"] = "Bearer " + token;
      } else {
        const urlString = window.location.href;
        if ("URL" in window) {
          const url = new URL(urlString);

          const autoZohoLogin = url.searchParams
            ? url.searchParams.get("autoZohoLogin")
            : undefined;
          if (autoZohoLogin) {
            // Store current path on sessionStorage
            sessionStorage.setItem(
              "pathAfterZohoLogin",
              window.location.pathname
            );

            // Redirect to Zoho Login
            window.location.href =
              // window.open(
              "https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.ALL,ZohoCRM.modules.ALL&client_id=" +
              process.env.REACT_APP_ZOHO_CLIENT_ID +
              "&response_type=code&access_type=offline&redirect_uri=" +
              encodeURIComponent(
                window.location.origin + "/zohooauth2callback"
              );
          }
        }
      }
      if (token && !authUser) {
        try {
          authUser = await apiEmpleo.post("auth/me", {});
          if (authUser) {
            getLoggedUserDataSuccess(authUser);

            // We have the authUser credentials here so we can load additional
            // data to make app more fast:
            // - Mis Ofertas 1st pages

            // Favourites
            if (process.env.REACT_APP_AFFINITY === "true") {
              if (authUser.userRoles.includes("ROLE_STAFF")) {
                // Also add staff data
                updateStaffData();
                updateCommercialDelegations();
              }
            }
          }
        } catch (error) {
          // We have an error here. apiEmpleo will reset user and the
          // process can continue without doing more
          console.log("Catching error on login!");
          snackErrorMessage(
            "Tu autorización ha caducado. Puedes iniciar sesión de nuevo"
          );
          userSetToken(null);
          userSetRefreshToken(null);
        }
      }
      initializeAppSuccess();

      if (history.action !== "REPLACE") {
        reportPageView(history.location);
      }
    };

    if (!isInitialized) {
      initializeApp();
    }

    const unlisten = history.listen((location: any, action: any) => {
      if (action !== "REPLACE") {
        reportPageView(location);
      }
    });

    const heartbeat = () => {
      store.dispatch(
        reportClientEvent({
          type: EVENT_HEARTBEAT,
          location: window.location.pathname,
        })
      );
    };

    // Configure a heartbeat
    const interval = setInterval(() => {
      if (!document.hidden) {
        heartbeat();
      }
    }, 15000);

    // Configure window focus / blur events
    if (!window.onfocus) {
      window.onfocus = function onFocus(e: any) {
        store.dispatch(
          reportClientEvent({
            type: EVENT_WINDOW_FOCUS,
          })
        );
      };
    }
    if (!window.onblur) {
      window.onblur = function onBlur(e: any) {
        store.dispatch(
          reportClientEvent({
            type: EVENT_WINDOW_BLUR,
          })
        );
      };
    }

    // Configure onleave / exit events
    if (!window.onbeforeunload) {
      window.onbeforeunload = function onUnload(e: any) {
        store.dispatch(
          reportClientEvent({
            type: EVENT_LEAVE,
          })
        );
      };
    }

    if (!window.onmessage) {
      window.onmessage = (ev: any) => {
        if (ev && ev.data && ev.data.type === "auth") {
          if (ev.data && ev.data.token) {
            store.dispatch(userSetToken(ev.data.token, true));
          }
          if (ev.data && ev.data.refresh_token) {
            store.dispatch(userSetRefreshToken(ev.data.refresh_token, true));
          }
          store.dispatch(getLoggedUserData());
          updateStaffData();
          updateCommercialDelegations();
        }
      };
    }

    return () => {
      clearInterval(interval);
      unlisten();
    };
    // eslint-disable-next-line
  }, []);

  if (location.pathname === "/") {
    const token = store.getState().auth.token;
    if (token === null) {
      return <Redirect to={APP_START_PAGE} />;
    } else if (initURL === "" || initURL === "/" || initURL === "/signin") {
      return <Redirect to={APP_START_PAGE} />;
    } else {
      return <Redirect to={initURL} />;
    }
  }

  return (
    <MuiThemeProvider theme={cuideoTheme}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <LangWrapper languageCode={languageCode}>
          <StyledAppMain background={background}>
            {isInitialized ? (
              <Fragment>
                {process.env.REACT_APP_AFFINITY === "true" && <Sidebar />}
                <StyledAppWithoutSidebarWrapper leftpadding={appPaddingLeft}>
                  {header.type === "simple" ? (
                    <SimpleHeader />
                  ) : (
                    <Header
                      headerType={header.type}
                      leftpadding={appPaddingLeft}
                    />
                  )}
                  <AppWithoutHeaderWrapper
                    key={location.pathname}
                    topMargin={appMarginTop}
                  >
                    <React.Suspense fallback={<div></div>}>
                      <Switch>
                        {process.env.REACT_APP_EMPLEO === "true" && (
                          <Route path={JOB_SIGN_IN} component={EmpleoSignIn} />
                        )}
                        {process.env.REACT_APP_AFFINITY === "true" && (
                          <Route
                            path="/affinitySignIn"
                            component={AffinitySignIn}
                          />
                        )}
                        {process.env.REACT_APP_EMPLEO === "true" && (
                          <Route
                            path="/empleoResetPassword"
                            component={JobsResetPassword}
                          />
                        )}
                        {process.env.REACT_APP_EMPLEO === "true" && (
                          <Route
                            path="/registroCuidador"
                            component={RegistroCuidador}
                          />
                        )}
                        <Route path="/settings" component={SettingsPage} />
                        <Route
                          path="/zohooauth2callback"
                          component={ZohoOauth2Callback}
                        />
                        <Route
                          path={`/ficha/:candidatoId`}
                          component={EmployeeProfilePage}
                        />

                        <Route path={`${match.url}`} component={MainApp} />
                        <Route component={Error404} />
                      </Switch>
                    </React.Suspense>
                  </AppWithoutHeaderWrapper>
                </StyledAppWithoutSidebarWrapper>
                <InfoView />
                {process.env.REACT_APP_AFFINITY === "true" &&
                  !location.pathname.includes("/service/") &&
                  authUser && <DelayedServiceReminder />}
                <Toaster
                  position="bottom-left"
                  reverseOrder={false}
                  containerStyle={{
                    left: appPaddingLeft * 16 + 15,
                  }}
                  toastOptions={{
                    // duration: 5000,
                    error: {
                      style: {
                        padding: "0.5rem 1rem",
                        color: "#611a15",
                        backgroundColor: "#fdecea",
                        fontFamily:
                          "'Source Sans Pro', Helvetiva, Arial, sans-serif",
                        fontWeight: 600,
                        lineHeight: 1.15,
                        borderLeft: "0.25rem solid #611a15",
                        borderRadius: "0.125rem",
                      },
                    },
                    loading: {
                      style: {
                        padding: "0.5rem 1rem",
                        fontFamily:
                          "'Source Sans Pro', Helvetiva, Arial, sans-serif",
                        lineHeight: 1.15,
                        borderLeft: "0.25rem solid #ccc",
                        borderRadius: "0.125rem",
                      },
                    },
                    success: {
                      style: {
                        padding: "0.5rem 1rem",
                        fontFamily:
                          "'Source Sans Pro', Helvetiva, Arial, sans-serif",
                        lineHeight: 1.15,
                        borderLeft: "0.25rem solid #4caf50",
                        borderRadius: "0.125rem",
                      },
                    },
                  }}
                />
              </Fragment>
            ) : (
              <LoadingPage />
            )}
            <Footer />
          </StyledAppMain>
        </LangWrapper>
      </IntlProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = ({
  settings,
  auth,
  layout,
  commonData,
}: {
  settings: { locale: string };
  auth: { authUser: any; token: string; initURL: string };
  layout: any;
  commonData: any;
}) => {
  const { authUser, initURL } = auth;
  const { appPaddingLeft, appMarginTop, width, background, header } = layout;
  const { provinces, tasks, countries, isInitialized } = commonData;
  return {
    authUser,
    initURL,
    appPaddingLeft,
    appMarginTop,
    provinces,
    tasks,
    countries,
    background,
    header,
    width,
    isInitialized,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  getLoggedUserData,
  getLoggedUserDataSuccess,
  getJobTasks,
  initializeApp,
  getOffersSuccess,
  updateWindowWidth,
  updateCandidatos,
  updateValoraciones,
  userSetToken,
  userSetRefreshToken,
  initializeAppSuccess,
  getStaffSuccess,
  getFavoriteCandidatosSuccess,
})(App);
