export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_STAFF = "ROLE_STAFF";
export const ROLE_MESSAGE_MANAGER = "ROLE_MSG_MNG";
export const ROLE_MSG_BETA = "ROLE_MSG_BETA";
export const ROLE_MANAGER = "ROLE_MANAGER";
export const ROLE_BOT_TESTER = "ROLE_BOT_TESTER";

export const ROLE_COMMERCIAL = "ROLE_COMMERCIAL";
export const ROLE_COORDINATION = "ROLE_COORDINATION";

export const USER_ROLES = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_STAFF: "ROLE_STAFF",
  ROLE_MESSAGE_MANAGER: "ROLE_MSG_MNG",
  ROLE_MSG_BETA: "ROLE_MSG_BETA",
  ROLE_MANAGER: "ROLE_MANAGER",
  ROLE_RECRUIT_MANAGER: "ROLE_RECRUIT_MANAGER",
  ROLE_PROMOTE_MANAGER: "ROLE_PROMOTE_MANAGER",
  ROLE_BOT_TESTER: "ROLE_BOT_TESTER",

  ROLE_COMMERCIAL: "ROLE_COMMERCIAL",
  ROLE_COORDINATION: "ROLE_COORDINATION",

  ROLE_ES_RECRUITER: "ROLE_ES_RECRUITER",
  ROLE_ES_PROMOTER: "ROLE_ES_PROMOTER",
  ROLE_ES_ON_SHIFT: "ROLE_ES_ON_SHIFT",
  // ROLE_ES_PHASE_TESTER: "ROLE_ES_PHASE_TESTER",

  ROLE_WA_TESTER: "ROLE_WA_TESTER",

  // Tests functionalities around CoreV3 Communications
  ROLE_COMM_TESTER: "ROLE_COMM_TESTER",

  ROLE_COMMERCIAL_SALES_COMPANY: "ROLE_COMMERCIAL_SALES_COMPANY"
} as const;

export type TUserRole = typeof USER_ROLES[keyof typeof USER_ROLES];
