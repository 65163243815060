export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const UPLOAD_START = "upload_start";
export const UPLOAD_SUCCESS = "upload_success";
export const UPLOAD_ERROR = "upload_error";
export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const SHOW_SNACK_MESSAGE = "show_snack_message";
export const HIDE_SNACK_MESSAGE = "hide_snack_message";
export const SHOW_BACKDROP = "show_backdrop";
export const HIDE_BACKDROP = "hide_backdrop";

// Initialization
export const GET_JOB_TASKS = "get_job_tasks";
export const GET_COUNTRIES = "get_countries";
export const GET_PROVINCES = "get_provinces";
export const GET_EDUCATION = "get_education";
export const GET_SPEAKS = "get_speaks";
export const GET_COMMERCIAL_DELEGATIONS = "get_commercial_delegations";
export const APP_INITIALIZED = "app_initialized";
export const APP_INITIALIZE = "app_initialize";
export const SET_COMMON_OPTIONS = "set_common_options";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNUP_USER_ERROR = "signup_user_error";
export const SIGNUP_USER_RESET_ERROR = "signup_user_reset_error";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNIN_USER_ERROR = "signin_user_error";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_REFRESH_TOKEN_SET = "user_refresh_token_set";
export const USER_SET_REMEMBER_ME = "user_set_remember_me";
export const GET_USER = "get_user";
export const GET_LOGGED_USER_DATA = "get_logged_user_data";
export const GET_LOGGED_USER_DATA_SUCCESS = "get_logged_user_data_success";
export const GET_LOGGED_USER_DATA_ERROR = "get_logged_user_data_error";
export const HIDE_INSCRIBETE_HIGHLIGHT = "hide_inscribete_hightlight";
export const SHOW_INSCRIBETE_HIGHLIGHT = "show_inscribete_hightlight";
export const SIGNIN_RESET_STATUS = "signin_reset_status";
export const DEFER_APPLY = "defer_apply";
export const REFRESING_TOKEN = "refreshing_token";
export const TOKEN_REFRESHED = "token_refreshed";

// Settings
export const SWITCH_LANGUAGE = "switch_language";

// Layout
export const CHANGE_LAYOUT = "change_layout";
export const WINDOW_WIDTH = "window_width";
export const LAYOUT_SET_PADDING_LEFT = "layout_set_padding_left";
export const LAYOUT_SET_MARGIN_TOP = "layout_set_margin_top";
export const LAYOUT_SET_BACKGROUND = "layout_set_background";
export const USER_DRAWER_MENU_OPEN = "user_drawer_menu_open";
export const USER_DRAWER_MENU_CLOSE = "user_drawer_menu_close";
export const LAYOUT_CHANGE_HEADER_TYPE = "layout_change_header_type";
export const LAYOUT_CHANGE_FOOTER_TYPE = "layout_change_footer_type";

// Empleo
export const GET_OFFERS = "get_offers";
export const GET_OFFER = "get_offer";
export const GET_OFFERS_SUCCESS = "get_offers_success";
export const GET_OFFER_SUCCESS = "get_offer_success";
export const SET_OFERTAS_FILTERS = "set_ofertas_filters";
export const SET_LOADING_OFERTAS = "set_loading_ofertas";
export const SET_LOADING_CANDIDATOS = "set_loading_candidatos";
export const GET_APPLIED_OFFERS = "get_applied_offers";
export const APPLY_OFFER = "apply_offer";
export const APPLY_OFFER_SUCCESS = "apply_offer_success";
export const UNAPPLY_OFFER = "unapply_offer";
export const UNAPPLY_OFFER_SUCCESS = "unapply_offer_success";
export const UPDATE_APPLIED_OFFERS = "update_applied_offers";
export const HIDE_WELCOME_POPUP = "hide_welcome_popup";
export const RESET_OFFERS = "reset_offers";
export const SET_NUM_OFERTAS_VISIBLES_HOME = "set_num_ofertas_visibles_home";

// Candidatos
export const GET_CANDIDATOS = "get_candidatos";
export const GET_CANDIDATOS_SUCCESS = "get_candidatos_success";
export const GET_CANDIDATO = "get_candidato";
export const GET_PARTIAL_CANDIDATO_SUCCESS = "get_partial_candidato_success";
export const GET_CANDIDATO_SUCCESS = "get_candidato_success";
export const UPDATE_CANDIDATOS_TABLE_STATE = "update_candidatos_table_state";
export const UPDATE_CANDIDATOS = "update_candidatos";
export const GET_FAVORITE_CANDIDATOS = "get_favorite_candidatos";
export const GET_FAVORITE_CANDIDATOS_SUCCESS =
  "get_favorite_candidatos_success";
export const FAVORITE_CANDIDATO = "favorite_candidato";
export const UNFAVORITE_CANDIDATO = "unfavorite_candidato";
export const FAVORITE_CANDIDATO_SUCCESS = "favorite_candidato_success";
export const UNFAVORITE_CANDIDATO_SUCCESS = "unfavorite_candidato_success";

// Usuarios
export const GET_USUARIOS = "get_usuarios";
export const GET_USUARIOS_SUCCESS = "get_usuarios_success";
export const GET_USUARIO = "get_usuario";
export const GET_USUARIO_SUCCESS = "get_usuario_success";
export const UPDATE_USUARIOS_TABLE_STATE = "update_usuarios_table_state";
export const UPDATE_USUARIOS = "update_usuarios";
export const GET_STAFF = "get_staff";
export const GET_STAFF_SUCCESS = "get_staff_success";

// Modal
export const MODAL_SHOW = "modal_show";
export const MODAL_CLOSE = "modal_close";
export const MODAL_RESET = "modal_reset";

// Valoraciones
export const GET_VALORACIONES = "get_valoraciones";
export const GET_VALORACIONES_SUCCESS = "get_valoraciones_success";
export const GET_VALORACION = "get_valoracion";
export const GET_VALORACION_SUCCESS = "get_valoracion";
export const UPDATE_VALORACIONES_TABLE_STATE =
  "update_valoraciones_table_state";
export const UPDATE_VALORACIONES = "update_valoraciones";
export const GET_MIS_VALORACIONES = "get_mis_valoraciones";
export const UPDATE_MIS_VALORACIONES = "update_mis_valoraciones";
export const RESET_MIS_VALORACIONES = "reset_mis_valoraciones";

// Actividades
export const GET_ACTIVIDADES = "get_actividades";
export const GET_ACTIVIDADES_SUCCESS = "get_actividades_success";

// Stats
export const GET_SITE_STATS = "get_site_stats";
export const GET_SITE_STATS_SUCCESS = "get_site_stats_success";
export const GET_STATISTIC = "get_statistic";
export const GET_STATISTIC_SUCCESS = "get_statistic_success";

// Registro
export const REGISTRO_SET_STEP = "registro_set_step";

// Filters
// export const FILTERS_SET_CONTRATOS = "filters_set_contratos";
// export const FILTERS_RESET_CONTRATOS = "filters_reset_contratos";
export const FILTERS_SET_CONTRATO = "filters_set_contrato";
export const FILTERS_RESET_CONTRATO = "filters_reset_contrato";
export const FILTERS_RESET_CONTRATO_SUGGESTIONS =
  "filters_reset_contrato_suggestions";
// export const FILTERS_SET_CANDIDATOS = "filters_set_candidatos";
// export const FILTERS_RESET_CANDIDATOS = "filters_reset_candidatos";
export const FILTERS_SET_FAVORITE_CANDIDATOS =
  "filters_set_favorite_candidatos";
export const FILTERS_SET_TRANSFER = "filters_set_transfer";

// Client events (used to report client things)
export const REPORT_CLIENT_EVENT = "report_client_event";
export const UPDATE_CLIENT_EVENT_STATE = "update_client_event_state";
export const SET_CLIENT_EVENT_SEND_STATUS = "set_client_event_send_status";

// Dashboard
export const GET_RECENT_APILOGS = "get_recent_api_logs";
export const GET_RECENT_APILOGS_SUCCESS = "get_recent_api_logs_success";
export const GET_RECENT_EXTERNAL_APILOGS = "get_recent_external_api_logs";
export const GET_RECENT_EXTERNAL_APILOGS_SUCCESS =
  "get_recent_external_api_logs_success";
export const GET_RECENT_ACTIVIDADES = "get_recent_actividades";
export const GET_RECENT_ACTIVIDADES_SUCCESS = "get_recent_actividades_success";
