import { TGetContractsCollectionItem } from "../../../../../api/contract/contract.types";
import useFormatters from "../../../../../components/FormattedFields/useFormatters";

export default function useContractToSelectedStateDialogFormInitialValues(contract: TGetContractsCollectionItem) {
  const {
    formatContractType,
    formatContractOvernightHours,
    formatContractPresencetHours,
    formatContractSalaryType
  } = useFormatters();
  return {
    Tipo_de_contrato: contract.Tipo_de_contrato
      ? {
          value: contract.Tipo_de_contrato,
          label: formatContractType(contract.Tipo_de_contrato)
        }
      : null,
    Horario_Servicio: contract.Horario_Servicio,
    Horas_jornada_semanal: contract.Horas_jornada_semanal,
    Horas_de_pernocta: contract.Horas_de_pernocta
      ? {
          value: contract.Horas_de_pernocta,
          label: formatContractOvernightHours(contract.Horas_de_pernocta)
        }
      : null,
    Noches_de_pernocta: contract.Noches_de_pernocta
      ? {
          value: contract.Noches_de_pernocta,
          label: contract.Noches_de_pernocta
        }
      : null,
    Horas_de_presencia: contract.Horas_de_presencia
      ? {
          value: contract.Horas_de_presencia,
          label: formatContractPresencetHours(contract.Horas_de_presencia)
        }
      : null,
    N_de_horas_de_presencia_a_la_semana: contract.N_de_horas_de_presencia_a_la_semana1,
    Fecha_de_inicio_real: contract.Fecha_de_inicio_real,
    Fecha_fin_contrato: contract.Fecha_fin_contrato,
    Per_odo_de_prueba: contract.Per_odo_de_prueba ,
    Tipo_de_salario: contract.Tipo_de_salario
      ? {
          value: contract.Tipo_de_salario,
          label: formatContractSalaryType(contract.Tipo_de_salario)
        }
      : null,
    Numero_de_pagas: contract.Numero_de_pagas
      ? {
          value: contract.Numero_de_pagas,
          label: contract.Numero_de_pagas
        }
      : null,
    Salario_Bruto_Mensual: contract.Salario_Bruto_Mensual,
    Cl_usulas_especiales: contract.Cl_usulas_especiales,
    Notas_Asesora_Seleccion: null
  };
};
