import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  UPLOAD_START,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  SHOW_SNACK_MESSAGE,
  HIDE_SNACK_MESSAGE,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  GET_JOB_TASKS,
  GET_COUNTRIES,
  GET_EDUCATION,
  GET_PROVINCES,
  APP_INITIALIZE,
  APP_INITIALIZED,
  SET_COMMON_OPTIONS,
  SHOW_BACKDROP,
  HIDE_BACKDROP,
  GET_COMMERCIAL_DELEGATIONS
} from "../constants/ActionTypes";
import axios from "../util/ApiEmpleo";

export const fetchStart = (opId: string, operation: string) => {
  return {
    type: FETCH_START,
    payload: {
      opId: opId,
      op: operation
    }
  };
};

export const fetchSuccess = (opId: string) => {
  return {
    type: FETCH_SUCCESS,
    payload: {
      opId: opId
    }
  };
};

export const fetchError = (error: string) => {
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const uploadStart = (opId: string, operation: string) => {
  return {
    type: UPLOAD_START,
    payload: {
      opId: opId,
      op: operation
    }
  };
};

export const uploadSuccess = (opId: string) => {
  return {
    type: UPLOAD_SUCCESS,
    payload: {
      opId: opId
    }
  };
};

export const uploadError = (error: string) => {
  return {
    type: UPLOAD_ERROR,
    payload: error
  };
};

export const snackSuccessMessage = (message: string) => {
  return {
    type: SHOW_SNACK_MESSAGE,
    payload: {
      type: "success",
      message: message
    }
  };
};

export const snackInfoMessage = (message: string) => {
  return {
    type: SHOW_SNACK_MESSAGE,
    payload: {
      type: "info",
      message: message
    }
  };
};

export const snackWarningMessage = (message: string) => {
  return {
    type: SHOW_SNACK_MESSAGE,
    payload: {
      type: "warning",
      message: message
    }
  };
};

export const snackErrorMessage = (message: string) => {
  return {
    type: SHOW_SNACK_MESSAGE,
    payload: {
      type: "error",
      message: message
    }
  };
};

export const hideSnackMessage = () => {
  return {
    type: HIDE_SNACK_MESSAGE,
    payload: {
      type: "success",
      message: ""
    }
  };
};

export const showMessage = (message: string) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const initializeApp = () => {
  return {
    type: APP_INITIALIZE
  };
};

export const initializeAppSuccess = () => {
  return {
    type: APP_INITIALIZED
  };
};

export const showBackdrop = () => {
  return {
    type: SHOW_BACKDROP
  };
};

export const hideBackdrop = () => {
  return {
    type: HIDE_BACKDROP
  };
};

export const setCommonOptions = (commonOptions: {
  paisesOptions: any[];
  provinciasOptions: any[];
  diasOptions: any[];
  mesesOptions: any[];
  anyosOptions: any[];
  idiomasOptions: any[];
  titulacionesOptions: any[];
  tareasOptions: any[];
  educacionOptions: any[];
  patologiasOptions: any[];
}) => {
  return {
    type: SET_COMMON_OPTIONS,
    payload: commonOptions
  };
};

const compareProvinces = (a: any, b: any) => {
  if (a.total_offers < b.total_offers) {
    return 1;
  }
  if (a.total_offers > b.total_offers) {
    return -1;
  }
  return 0;
};

export const getJobTasks = () => {
  return (dispatch: Function) => {
    dispatch({ type: FETCH_START });
    axios
      .get("job-task/language/207ad905-5d87-407b-9f22-8c111c612ad1")
      .then(({ data }) => {
        // dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_JOB_TASKS, payload: data });
        axios
          .get("country/language/207ad905-5d87-407b-9f22-8c111c612ad1")
          .then(({ data }) => {
            dispatch({ type: GET_COUNTRIES, payload: data });
            axios
              .get("education/language/207ad905-5d87-407b-9f22-8c111c612ad1")
              .then(({ data }) => {
                dispatch({ type: GET_EDUCATION, payload: data });
                axios
                  .get("province/all")
                  .then(({ data }) => {
                    data.sort(compareProvinces);
                    const filteredProvinces = data.filter(
                      (item: any) => item.total_offers > 0
                    );
                    dispatch({
                      type: GET_PROVINCES,
                      payload: filteredProvinces
                    });
                    dispatch({ type: FETCH_SUCCESS });
                  })
                  .catch(function(error) {
                    console.log("Fetch error");
                  });
              })
              .catch(function(error) {
                console.log("Fetch Error");
              });
          })
          .catch(function(error) {
            // Error fetching countries
            console.log("Fetch error");
          });
      })
      .catch(function(error) {
        console.log("Fetch error");
      });
  };
};

export const getCommercialDelegations = ({ params }: { params: any }) => {
  return {
    type: GET_COMMERCIAL_DELEGATIONS,
    payload: params
  };
};
