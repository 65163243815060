import { Grid, IconButton, Tab, Tabs } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import VideocamIcon from "@material-ui/icons/Videocam";
import React, { Fragment } from "react";
import styled from "styled-components";
import FichaCoordinadora from "../../coordinadora/components/CoordinatorFile/ES/CoordinatorFile";
import { default as FichaCoordinadoraFr } from "../../coordinadora/components/CoordinatorFile/FR/CoordinatorFile_Fr";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import ContratosTab from "./ContractsTab/ContractsTab";
// import EmployeeHeader from "./EmployeeHeader/EmployeeHeader";
import FichaPublicaTab from "./FichaPublicaTab";
import InscripcionesTab from "./InscriptionsTab/InscriptionsTab";
import EntrevistasTab from "./InterviewsTab";
import VideoentrevistaTab from "./VideoInterviewTab";
import { FormattedMessage } from "react-intl";
import MessagesTab from "./WaMessagesTab/MessagesTab";
import { TSendMessageRequestContext } from "../../../apiCoreV3/message/apiSendMessage";
import { CandidateHeader } from "../features/candidate";
import { TOnCandidateChangeFn } from "../features/candidate/types/candidate.types";

const StyledFullProfile = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTop = styled.div``;

const StyledBottom = styled.div`
  position: relative;
  flex: 1 1 0;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 72, 120, 0.25);
    width: 0.5rem;
    border-radius: 0.25rem;
  }
`;

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
`;

const StyledCandidatoTabs = styled(Tabs)`
  background-color: ${cuideoTheme.palette.common.white};
  border-top: 2px solid rgba(0, 72, 120, 0.5);
  min-height: 2.5rem;
  .Mui-selected {
    font-weight: 700;
  }
  .MuiTabs-indicator {
    background-color: #ebc200;
    height: 4px;
    bottom: -1px;
  }
`;

const StyledCandidatoTab = styled(Tab)`
  text-transform: none;
  font-weight: 400;
  line-height: 1.15;
  min-width: 5rem;
  font-size: 0.875rem;
  color: ${cuideoTheme.palette.primary.main};
  min-height: 2.5rem;
  .MuiTab-wrapper {
    white-space: nowrap;
    display: block;
    line-height: 1.25rem;
  }
`;

const StyledTabBadge = styled.span`
  background-color: #ddd;
  color: ${cuideoTheme.palette.primary.main};
  font-size: 0.75rem;
  border-radius: 50%;
  margin-left: 0.375rem;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1.25rem;
  display: inline-block;
`;

const StyledPendingVideoIcon = styled(VideocamIcon)`
  margin-top: 0.25rem;
  font-size: 1.25rem;
  color: #f29339;
  opacity: 0.5;
`;

const StyledCompletedVideoIcon = styled(VideocamIcon)`
  margin-top: 0.25rem;
  font-size: 1.25rem;
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;
`;

const StyledFinalistVideoIcon = styled(VideocamIcon)`
  margin-top: 0.25rem;
  font-size: 1.25rem;
  color: ${cuideoTheme.palette.success.main};
  opacity: 0.5;
`;

export interface CandidatoFullProfileProps {
  candidato: any;
  valoracion?: any;
  onChange?: TOnCandidateChangeFn;
  onUnselect?: () => void;
  onTabChange: (newTab: number) => void;
  tab: number;
  onEmployeeProfileChange: () => void;
  context?: TSendMessageRequestContext;
  currentContract?: any;
}

const CandidatoFullProfile = (props: CandidatoFullProfileProps) => {
  const {
    candidato,
    onChange,
    onUnselect,
    valoracion,
    tab,
    onTabChange,
    onEmployeeProfileChange,
    context,
    currentContract,
  } = props;

  const handleHeaderChange: TOnCandidateChangeFn = (op, changedCandidate) => {
    // // Can be used for temp status
    // changedCandidate.status = "discard_candidato";

    onChange && onChange(op, changedCandidate);
  };

  const handleAvatarChange = () => {
    onEmployeeProfileChange?.();
  };

  const handleCloseButtonClick = () => {
    if (onUnselect) {
      onUnselect();
    }
  };

  const handleCandidatoTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ) => {
    onTabChange(newValue);
  };

  return (
    <StyledFullProfile
      className="MuiCuideo-CandidatoFullProfile"
      key={`CandidatoFullProfile-${candidato.id}`}
    >
      <StyledTop>
        <StyledCloseButton color="primary" onClick={handleCloseButtonClick}>
          <CloseIcon />
        </StyledCloseButton>
        <CandidateHeader
          candidate={candidato}
          // handleStatusChange={handleHeaderStatusChange}
          onChange={handleHeaderChange}
          onAvatarChange={handleAvatarChange}
          // disabled={isMutating}
          valuation={valoracion}
          currentContract={currentContract}
        />
        <StyledCandidatoTabs
          value={tab}
          textColor="primary"
          onChange={handleCandidatoTabChange}
          variant="fullWidth"
        >
          <StyledCandidatoTab
            label={
              <FormattedMessage
                id="EmployeeFullProfile.Profile"
                defaultMessage="Perfil"
              />
            }
          />
          <StyledCandidatoTab
            label={
              <Grid
                container
                spacing={2}
                alignItems="center"
                wrap="nowrap"
                justifyContent="center"
              >
                <Grid item>
                  <FormattedMessage
                    id="EmployeeFullProfile.Videointerviews"
                    defaultMessage="Videoentrevista"
                  />
                </Grid>
                <Grid item>
                  {candidato.interviewStatus === "completed" && (
                    <StyledCompletedVideoIcon />
                  )}
                  {candidato.interviewStatus === "pending" && (
                    <StyledPendingVideoIcon />
                  )}
                  {candidato.interviewStatus === "finalist" && (
                    <StyledFinalistVideoIcon />
                  )}
                </Grid>
              </Grid>
            }
          />
          <StyledCandidatoTab
            label={
              <FormattedMessage
                id="EmployeeFullProfile.PublicProfile"
                defaultMessage="Ficha pública"
              />
            }
          />
          <StyledCandidatoTab
            label={
              <Fragment>
                <FormattedMessage
                  id="EmployeeFullProfile.OffersApplied"
                  defaultMessage="Ofertas Inscritas"
                />
                <StyledTabBadge>
                  {candidato.numOfertasInscritasActivas}
                </StyledTabBadge>
              </Fragment>
            }
            disabled={candidato.numOfertasInscritasActivas === 0}
          />
          <StyledCandidatoTab
            label={
              <Fragment>
                <FormattedMessage
                  id="EmployeeFullProfile.Contracts"
                  defaultMessage="Contratos"
                />
                <StyledTabBadge>{candidato.numContratos}</StyledTabBadge>
              </Fragment>
            }
            disabled={candidato.numContratos === 0}
          />
          <StyledCandidatoTab
            label={
              <Fragment>
                <FormattedMessage
                  id="EmployeeFullProfile.Interviews"
                  defaultMessage="Entrevistas"
                />
                <StyledTabBadge>{candidato.numEntrevistas}</StyledTabBadge>
              </Fragment>
            }
            disabled={candidato.numEntrevistas === 0}
          />
          <StyledCandidatoTab
            label={
              <Fragment>
                <FormattedMessage
                  id="EmployeeFullProfile.Communications"
                  defaultMessage="Mensajes"
                />
                {/* <StyledTabBadge>
                  {candidato.numCommunicationIntents}
                </StyledTabBadge> */}
              </Fragment>
            }
            // disabled={candidato.numCommunicationIntents === 0}
          />
        </StyledCandidatoTabs>
      </StyledTop>
      <StyledBottom>
        {tab === 0 && candidato.fullLoaded && (
          <Fragment>
            {candidato.Business_Country === "fr" ? (
              <FichaCoordinadoraFr
                candidato={candidato}
                showFixedHeader={false}
                onEmployeeChanged={onEmployeeProfileChange}
              />
            ) : (
              <FichaCoordinadora
                candidato={candidato}
                showFixedHeader={false}
                onEmployeeChanged={onEmployeeProfileChange}
              />
            )}
          </Fragment>
        )}
        {tab === 1 && candidato.fullLoaded && (
          <VideoentrevistaTab employee={candidato} />
        )}
        {tab === 2 && candidato.fullLoaded && (
          <FichaPublicaTab candidato={candidato} />
        )}
        {tab === 3 && candidato.fullLoaded && (
          <InscripcionesTab candidato={candidato} />
        )}
        {tab === 4 && candidato.fullLoaded && (
          <ContratosTab candidato={candidato} />
        )}
        {tab === 5 && candidato.fullLoaded && (
          <EntrevistasTab candidato={candidato} />
        )}
        {tab === 6 && candidato.fullLoaded && (
          <MessagesTab candidato={candidato} context={context} />
        )}
      </StyledBottom>
    </StyledFullProfile>
  );
};

export default CandidatoFullProfile;
