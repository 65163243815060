import React from "react";
import styled from "styled-components";
import IntlMessages from "../../../util/IntlMessages";
import { Typography, Checkbox } from "@material-ui/core";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import ContratoTooltip from "../../../components/Contrato/ContratoTooltip";

const StyledRow = styled.div`
  margin: 0.5rem 1rem;
  padding: 0.5rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid ${cuideoTheme.palette.divider};

  &.highlighted {
    background-color: rgba(1,1,1,0.05);
  }
`;

const StyledCell = styled.div`
  padding: 0 0.25rem;
  box-sizing: border-box;

  :nth-child(1) {
    min-width: 5%;
    max-width: 5%;
  }
  :nth-child(2) {
    min-width: 15%;
    max-width: 15%;
  }
  :nth-child(3) {
    min-width: 22.5%;
    max-width: 22.5%;
  }
  :nth-child(4) {
    min-width: 22.5%;
    max-width: 22.5%;
  }
  :nth-child(5) {
    min-width: 15%;
    max-width: 15%;
  }
  :nth-child(6) {
    min-width: 20%;
    max-width: 20%;
  }
`;

const StyledLabel = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  opacity: 0.5;

  font-size: 0.75rem;
  line-height: 1.25;
`;

const StyledData = styled(Typography)`
  color: ${cuideoTheme.palette.primary.main};
  font-size: 1rem;
  line-height: 1.25;
`;

const StyledContratoTooltipWrapper = styled.div`
  .MuiTypography-root {
    text-align: left;
  }
`;

const StyledCheckbox = styled(Checkbox)``;

const ContratoTransferRow = (props: any) => {
  const { contrato, selected, toggleRowSelection, highlighted } = props;

  const handleChange = () => {
    toggleRowSelection(contrato.id);
  };

  return (
    <StyledRow className={highlighted ? 'highlighted' : ''}>
      <StyledCell>
        <StyledCheckbox
          color="primary"
          checked={selected}
          onChange={handleChange}
        />
      </StyledCell>
      <StyledCell>
        <StyledContratoTooltipWrapper>
          <ContratoTooltip contrato={contrato} />
        </StyledContratoTooltipWrapper>
      </StyledCell>
      <StyledCell>
        <StyledLabel>
          <IntlMessages id="Tipo de trato" />
        </StyledLabel>
        <StyledData>{contrato.Tipo_de_Trato}</StyledData>
      </StyledCell>
      <StyledCell>
        <StyledLabel>
          <IntlMessages id="Cliente" />
        </StyledLabel>
        <StyledData>{contrato._Nombre_del_cliente}</StyledData>
      </StyledCell>
      <StyledCell>
        <StyledLabel>
          <IntlMessages id="Estado contrato" />
        </StyledLabel>
        <StyledData>{contrato.Estado}</StyledData>
      </StyledCell>
      <StyledCell>
        <StyledLabel>
          <IntlMessages id="Owner" />
        </StyledLabel>
        <StyledData>{contrato.ownerName}</StyledData>
      </StyledCell>
    </StyledRow>
  );
};

export default ContratoTransferRow;
