import {
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { motion } from "framer-motion";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import cuideoTheme from "../../../containers/themes/defaultTheme";
import { VALORACION_RECOMENDACION } from "../../../util/defaults/valoracion";
import { validatePhone } from "../../../util/validators/yupValidators";
import {
  ESquad,
  SQUADS_ABLE_TO_RUN_BOT,
} from "../features/squad/types/squad.types";
import AssignCandidatoButton from "./AssignCandidatoButton";
import CreateCommunicationIntentsButton from "./CreateCommIntentsButton";
import DescartaValoracionesButton from "./DescartaValoracionesButton";

const StyledFooter = styled.div`
  padding: 1rem 1rem 1rem 5rem;
  background-color: ${cuideoTheme.palette.primary.main};
`;

const StyledRow = styled(Grid)``;

const StyledLeftSection = styled(Grid)`
  text-align: left;
`;

const StyledNumberText = styled(Typography)`
  color: ${cuideoTheme.palette.common.white};
  font-weight: 700;
`;

const StyledCenterSection = styled(Grid)`
  text-align: center;
`;

const StyledRightSection = styled(Grid)`
  text-align: right;
`;

const StyledCloseButton = styled(IconButton)`
  color: ${cuideoTheme.palette.common.white};
`;

const StyledAssignWrapper = styled.div`
  color: ${cuideoTheme.palette.common.white};
  .MuiCuideo-AssignButton {
    padding: 0.375rem;
    min-width: unset;
    border-radius: 50%;
  }
`;

const StyledDeleteWrapper = styled.div`
  color: ${cuideoTheme.palette.common.white};
`;

const StyledCommunicationIntentWrapper = styled.div`
  .MuiIconButton-root {
    border: none;

    .CMuiIcon {
      color: ${cuideoTheme.palette.common.white};
    }
  }
`;

const footerVariants = {
  enter: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.25 },
    display: "block",
  },
  exit: {
    opacity: 0,
    y: 25,
    transition: { duration: 0.25 },
    transitionEnd: {
      display: "none",
    },
  },
};

export interface CandidatosMultiSelectFooterProps {
  onChange: Function;
  currentContract: any;
  valoraciones: {
    id: string;
    Estado_en_proceso: string;
    candidato: {
      id: string;
      botAvailable: boolean;
      Phone?: string;
      numOpenCommunicationIntents: number;
      numCommunicationIntentsLastDay?: number;
    };
    contrato?: {
      id: string;
      Squad?: ESquad;
      numCommunicationIntents: number;
    };
  }[];
  onUnselectAll: Function;
}

const ValoracionesMultiSelectFooter = (
  props: CandidatosMultiSelectFooterProps
) => {
  const { currentContract, valoraciones, onChange, onUnselectAll } = props;

  const handleAssignChange = () => {
    onChange();
  };

  const handleDiscardComplete = () => {
    onChange();
  };

  const handleCommunicationIntentsCreated = () => {
    onChange();
  };

  const smsPhones: string[] = [];
  valoraciones.forEach((valoracion: any) => {
    if (
      valoracion.candidato &&
      valoracion.candidato.Phone &&
      validatePhone(valoracion.candidato.Phone)
    ) {
      smsPhones.push(valoracion.candidato.Phone);
    }
  });

  let canMultiDelete = true;
  let canMultiLaunchFlow = false;
  let contractId = null;
  valoraciones.forEach((valuation) => {
    if (valuation.Estado_en_proceso === VALORACION_RECOMENDACION) {
      if (
        valuation.candidato &&
        valuation.candidato.Phone &&
        validatePhone(valuation.candidato.Phone) &&
        valuation.contrato?.Squad &&
        SQUADS_ABLE_TO_RUN_BOT.includes(valuation.contrato.Squad)
      ) {
        canMultiLaunchFlow = true;
      }
    } else {
      canMultiDelete = true;
    }
    if (valuation.contrato?.id) {
      contractId = valuation.contrato.id;
    }
  });

  const employeeIds = valoraciones.map((valuation) => {
    return valuation.candidato.id;
  });

  return (
    <motion.div
      variants={footerVariants}
      animate={valoraciones.length ? "enter" : "exit"}
      initial="exit"
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <StyledFooter>
        <StyledRow
          container
          spacing={8}
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledLeftSection item xs={12} md={4}>
            <StyledNumberText>
              <FormattedMessage
                id="valmultiselectfooter.valoraciones"
                defaultMessage="{numValoraciones} valoraciones seleccionadas"
                values={{ numValoraciones: valoraciones.length }}
              />
            </StyledNumberText>
          </StyledLeftSection>
          <StyledCenterSection item xs={12} md={4}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={4}
            >
              <Grid item>
                <Tooltip
                  title={
                    <FormattedMessage
                      id="multiselect.assign"
                      defaultMessage="Asignar candidatos a otros contratos"
                    />
                  }
                >
                  <StyledAssignWrapper>
                    <AssignCandidatoButton
                      currentContract={currentContract}
                      candidatos={valoraciones.map((val: any) => {
                        if (val.candidato) {
                          return val.candidato;
                        }
                        return null;
                      })}
                      handleChange={handleAssignChange}
                    />
                  </StyledAssignWrapper>
                </Tooltip>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              {canMultiDelete && (
                <Grid item>
                  <Tooltip
                    title={
                      <FormattedMessage
                        id="multiselect.Eliminar candidatos del contrato actual"
                        defaultMessage="Eliminar candidatos del contrato actual"
                      />
                    }
                  >
                    <StyledDeleteWrapper>
                      <DescartaValoracionesButton
                        valoraciones={valoraciones}
                        onDiscard={handleDiscardComplete}
                      />
                    </StyledDeleteWrapper>
                  </Tooltip>
                </Grid>
              )}
              {canMultiLaunchFlow && !!contractId && (
                <Grid item>
                  <StyledCommunicationIntentWrapper>
                    <CreateCommunicationIntentsButton
                      contractId={contractId}
                      employeeIds={employeeIds}
                      onCommunicationIntentsCreated={
                        handleCommunicationIntentsCreated
                      }
                    />
                  </StyledCommunicationIntentWrapper>
                </Grid>
              )}
            </Grid>
          </StyledCenterSection>
          <StyledRightSection item xs={12} md={4}>
            <StyledCloseButton
              onClick={() => {
                onUnselectAll();
              }}
            >
              <CloseIcon />
            </StyledCloseButton>
          </StyledRightSection>
        </StyledRow>
      </StyledFooter>
    </motion.div>
  );
};

export default React.memo(
  (props: CandidatosMultiSelectFooterProps) => {
    return <ValoracionesMultiSelectFooter {...props} />;
  },
  (prevProps, nextProps) => {
    if (
      prevProps.valoraciones !== nextProps.valoraciones ||
      prevProps.onChange !== nextProps.onChange ||
      prevProps.onUnselectAll !== nextProps.onUnselectAll
    ) {
      return false;
    }
    return true;
  }
);
