import { Box, Button, Grid, Popover, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import React, { Fragment, useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import FilterItem from "./FilterItem";
import { StyledFiltersButton, StyledPopover } from "./FiltersButtonSC";
import { useAuthUser } from "../../../../reducers/hooks/useAuthUser";
import { userIsCommercialSalesCompany } from "../../models/Staff/Staff";

export type Filter = {
  name: string;
  filterLabel: string;
  type: "select" | "multi" | "check";
  options?: {
    value: string;
    label: string;
  }[];
  hasSelectAllOption?: boolean;
};

export type FilterValue = {
  isActive: boolean;
  isOpen: boolean;
  value: string | string[];
  name: string;
};

export interface IFiltersButtonProps {
  filters: Filter[];
  filtersValues: any;
  onFiltersChange: (filtersValues: any) => void;
  resetOptions?: Record<string, any>;
}

const FiltersButton = (props: IFiltersButtonProps) => {
  const { filters, filtersValues, onFiltersChange, resetOptions } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [filtersState, setFiltersState] = useState({} as any);
  const [filtersStateAtOpen, setFiltersStateAtOpen] = useState({} as any);
  const { authUser } = useAuthUser();

  const filtersToValidArray = useCallback((values: any) => {
    const validFilters = {} as any;
    Object.keys(values).forEach((key: string) => {
      if (Array.isArray(values[key].value)) {
        validFilters[key] = {
          isActive: !!values[key].value.length,
          isOpen: !!values[key].value.length,
          value: values[key].value,
          name: key
        };
      } else {
        validFilters[key] = {
          isActive: !!values[key].value,
          isOpen: !!values[key].value,
          value: values[key].value,
          name: key
        };
      }
    });
    return validFilters;
  }, []);

  useEffect(() => {
    const validFilters = filtersToValidArray(filtersValues);
    setFiltersState(validFilters);
  }, [filtersToValidArray, filtersValues]);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setFiltersStateAtOpen({ ...filtersState });
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setFiltersState({ ...filtersStateAtOpen });
    setAnchorEl(null);
  };

  const handleCancelButton = () => {
    setFiltersState({ ...filtersStateAtOpen });
    setAnchorEl(null);
  };

  const handleApplyButton = () => {
    onFiltersChange(filtersState);
    setAnchorEl(null);
  };

  const handleFiltersReset = () => {
    const validFilters = filtersToValidArray({
      ...resetOptions,
    });
    setFiltersState(validFilters);
  };

  const handleFilterChange = useCallback(
    (filterName: string, isOpen: boolean, value: string | string[]) => {
      if (Array.isArray(value)) {
        setFiltersState((prevFiltersState: any) => ({
          ...prevFiltersState,
          [filterName]: {
            isActive: !!value.length,
            isOpen: isOpen,
            value: value,
            name: filterName
          },
        }));
      } else {
        // Complex condition: If it is a select and has an empty option we
        // must deactivate it when closing the accordion
        const filter = filters.find((item) => item.name === filterName);
        if (filter) {
          const emptyOption =
            filter && filter.options
              ? filter.options.find((item) => item.value === "")
              : null;
          if (emptyOption) {
            setFiltersState((prevFiltersState: any) => ({
              ...prevFiltersState,
              [filterName]: {
                isActive: !!value,
                isOpen: isOpen,
                value: value,
                name: filterName
              },
            }));
          } else {
            setFiltersState((prevFiltersState: any) => ({
              ...prevFiltersState,
              [filterName]: {
                isActive: !!isOpen && !!value,
                isOpen: isOpen,
                value: value,
                name: filterName
              },
            }));
          }
        }
      }
    },
    [setFiltersState, filters]
  );

  let numActiveFilters = 0;
  Object.values(filtersState).forEach((filter: any) => {
    if (filter.isActive) {
      if (!userIsCommercialSalesCompany(authUser) || (userIsCommercialSalesCompany(authUser) && filter.name !== 'commercialDelegation')) {
        numActiveFilters++;
      }
    }
  });

  return (
    <Fragment>
      <StyledFiltersButton
        className="CMuiFiltersButton"
        color="primary"
        variant="outlined"
        onClick={handleClick}
      >
        <Box className="CMuiFiltersButtonTextContainer">
          <FilterListIcon />
          <Typography className="CMuiFiltersButtonText">
            <FormattedMessage
              id="FiltersButton.Filters"
              defaultMessage="Filtros"
            />
          </Typography>
        </Box>
        <Typography className="CMuiFiltersButtonNumber">
          {numActiveFilters}
        </Typography>
      </StyledFiltersButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableRestoreFocus
      >
        <StyledPopover className="CMuiFiltersPaper">
          <Grid
            className="CMuiFiltersPaperActions"
            container
            spacing={4}
            alignItems="center"
          >
            <Grid item xs={4}>
              <Grid container spacing={4} alignItems="center">
                <Grid item>
                  <Button
                    className="CMuiFiltersPaperCancelButton"
                    variant="outlined"
                    onClick={handleCancelButton}
                  >
                    <FormattedMessage
                      id="FiltersButton.Cancel"
                      defaultMessage="Cancelar"
                    />
                  </Button>
                </Grid>
                {resetOptions && (
                  <Grid item>
                    <Button
                      className="CMuiFiltersPaperResetButton"
                      variant="outlined"
                      onClick={handleFiltersReset}
                    >
                      <FormattedMessage
                        id="FiltersButton.Reset"
                        defaultMessage="Reset"
                      />
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center" }}>
              <Typography className="CMuiFiltersPaperActionsTitle">
                <FormattedMessage
                  id="FiltersButton.Title"
                  defaultMessage="Filtros"
                />
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Button
                className="CMuiFiltersPaperApplyButton"
                variant="contained"
                color="primary"
                onClick={handleApplyButton}
              >
                <FormattedMessage
                  id="FiltersButton.Aplicar"
                  defaultMessage="Aplicar"
                />
              </Button>
            </Grid>
          </Grid>
          <Box className="CMuiFiltersPaperContent">
            <Box className="CMuiFiltersPaperContentLeft">
              {filters
                .filter((filter, index) => !(index % 3))
                .map((filter, index) => {
                  return (
                    <FilterItem
                      key={`fb-${filter.name}`}
                      filter={filter}
                      filterState={filtersState[filter.name]}
                      onFilterChange={handleFilterChange}
                    />
                  );
                })}
            </Box>
            <Box className="CMuiFiltersPaperContentMiddle">
              {filters
                .filter((filter, index) => !((index + 2) % 3))
                .map((filter, index) => {
                  return (
                    <FilterItem
                      key={`fb-${filter.name}`}
                      filter={filter}
                      filterState={filtersState[filter.name]}
                      onFilterChange={handleFilterChange}
                    />
                  );
                })}
            </Box>
            <Box className="CMuiFiltersPaperContentRight">
              {filters
                .filter((filter, index) => !((index + 1) % 3))
                .map((filter, index) => {
                  return (
                    <FilterItem
                      key={`fb-${filter.name}`}
                      filter={filter}
                      filterState={filtersState[filter.name]}
                      onFilterChange={handleFilterChange}
                    />
                  );
                })}
            </Box>
          </Box>
        </StyledPopover>
      </Popover>
    </Fragment>
  );
};

export default FiltersButton;
