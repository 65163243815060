import { apiEmpleo } from "../../util/ApiEmpleo";
import { CONTRACT_PHASE, TGetContractsCollectionItem } from "./contract.types";

export interface IApiListContractsParams {
  page?: number;
  itemsPerPage?: number;
  partial?: number;
  publicar?: string;
  tipoDeTrato?: string;
  origen?: string;
  estado?: string[];
  provinciaOferta?: string;
  phase?: (typeof CONTRACT_PHASE.RECRUIT | typeof CONTRACT_PHASE.PROMOTE)[];
  owner?: any;
  commercialDelegation?: string;
  "order[createdAt]"?: "asc" | "desc";
  "order[priority]"?: "asc" | "desc";
  "order[recruitPriority]"?: "asc" | "desc";
  "order[fechaObjetivo]"?: "asc" | "desc";
  "order[cvDate]"?: "asc" | "desc";
  "order[squad]"?: "asc" | "desc";
}

const apiListContracts = async (params: IApiListContractsParams) => {
  const res: {
    "hydra:member": TGetContractsCollectionItem[];
    "hydra:totalItems": number;
  } = await apiEmpleo.get("offers", { params });

  return res;
};

export { apiListContracts };
