import { AxiosResponse } from "axios";
import { apiCoreV3 } from "../apiCoreV3";

export type TCustomerNewestEmployerResponse = {
  id: string;
  firstname: string;
  surname: string;
  lastname: string;
  completed: boolean;
}

export const apiCustomerNewestEmployer = async (customerId: string) => {
  try {
    const res: AxiosResponse<TCustomerNewestEmployerResponse> =
    await apiCoreV3.get(
      `customer/${customerId}/employer`
    );

    return res.data;
  } catch (e: any) {
    if (e &&
      e.response &&
      e.response.data &&
      e.response.data.error_code &&
      e.response.data.error_code === 'employer_not_found' &&
      e.response.data.message &&
      e.response.data.message.includes('Employer not found for customer id')
    ) {
      throw new Error('Employer not found for customer');
    }
  }
}
