import { Moment } from "moment";
import { TUserRole } from "../api/security";
import {
  APPLY_OFFER,
  APPLY_OFFER_SUCCESS,
  DEFER_APPLY,
  GET_LOGGED_USER_DATA,
  GET_LOGGED_USER_DATA_ERROR,
  GET_LOGGED_USER_DATA_SUCCESS,
  HIDE_INSCRIBETE_HIGHLIGHT,
  INIT_URL,
  REFRESING_TOKEN,
  SHOW_INSCRIBETE_HIGHLIGHT,
  SIGNIN_RESET_STATUS,
  SIGNIN_USER,
  SIGNIN_USER_ERROR,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_ERROR,
  SIGNUP_USER_RESET_ERROR,
  SIGNUP_USER_SUCCESS,
  TOKEN_REFRESHED,
  UNAPPLY_OFFER,
  USER_DATA,
  USER_SET_REMEMBER_ME,
  USER_TOKEN_SET,
} from "../constants/ActionTypes";

export type TAuthenticatedUser = {
  userId: string;
  userAvatar: string | null;
  userName: string;
  userFirstName: string | null;
  userEmail: string;
  userRoles: TUserRole[];
  userFullName: string;
  userInitials: string;
  userPermissions: string[];
  userPreferredLanguage: string;
  numOwnedContracts?: number;
  numOwnedServices?: number;
  userSalesCompany?: string | null;

  // Id in CoreV3 environment which is different
  coreV3Id?: string;
};

export type TAuthState = {
  token: string | null;
  tokenExpiry: Moment | null;
  refreshToken: string | null;
  isRefreshingToken: boolean;
  rememberMe: boolean;
  initURL: string;
  authUser: null | TAuthenticatedUser;
  appliedOffers: any;
  registerStatus: null | string;
  registerMessage: null | string;
  loggingStatus: null | string;
  loggingMessage: null | string;
  showInscribeteHighlight: boolean;
  deferredApplies: any[];
};

const INIT_STATE: TAuthState = {
  token: null,
  tokenExpiry: null,
  refreshToken: null,
  isRefreshingToken: false,
  rememberMe: true,
  initURL: "",
  authUser: null,
  appliedOffers: [],
  registerStatus: null,
  registerMessage: null,
  loggingStatus: null,
  loggingMessage: null,
  showInscribeteHighlight: false,
  deferredApplies: [],
};

let token = localStorage.getItem("token");
if (!token) {
  token = sessionStorage.getItem("token");
  INIT_STATE.rememberMe = false;
}
if (token) {
  INIT_STATE.token = JSON.parse(token);
}
let tokenExpiry = localStorage.getItem("tokenExpiry");
if (!tokenExpiry) {
  tokenExpiry = sessionStorage.getItem("tokenExpiry");
}
if (tokenExpiry) {
  INIT_STATE.tokenExpiry = JSON.parse(tokenExpiry);
}
let refreshToken = localStorage.getItem("refreshToken");
if (!refreshToken) {
  refreshToken = sessionStorage.getItem("refreshToken");
}
if (refreshToken) {
  INIT_STATE.refreshToken = JSON.parse(refreshToken);
}
const authUser = localStorage.getItem("user");
if (authUser) {
  INIT_STATE.authUser = JSON.parse(authUser);
}

const authReducer = (
  state = INIT_STATE,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }
    case SIGNUP_USER: {
      return {
        ...state,
        registerStatus: "registering",
        registerMessage: null,
      };
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        registerStatus: "registered",
        registerMessage: null,
        authUser: action.payload,
      };
    }
    case SIGNUP_USER_ERROR: {
      return {
        ...state,
        registerStatus: "error",
        registerMessage: action.payload,
      };
    }
    case SIGNUP_USER_RESET_ERROR: {
      return {
        ...state,
        registerStatus: null,
        registerMessage: null,
      };
    }
    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload.token,
        tokenExpiry: action.payload.tokenExpiry,
      };
    }
    case USER_SET_REMEMBER_ME: {
      return {
        ...state,
        rememberMe: action.payload,
      };
    }
    case REFRESING_TOKEN: {
      return {
        ...state,
        isRefreshingToken: true,
      };
    }
    case TOKEN_REFRESHED: {
      return {
        ...state,
        isRefreshingToken: false,
      };
    }
    case SIGNOUT_USER: {
      return {
        ...state,
        token: null,
        refreshToken: null,
        tokenExpiry: null,
        authUser: null,
        initURL: "",
        showInscribeteHighlight: false,
        appliedOffers: [],
        deferredApplies: [],
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        refreshToken: null,
        tokenExpiry: null,
        authUser: null,
        initURL: "",
        showInscribeteHighlight: false,
        appliedOffers: [],
        deferredApplies: [],
      };
    }
    case SIGNIN_USER: {
      return {
        ...state,
        loggingMessage: null,
        loggingStatus: "loggingIn",
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loggingMessage: null,
        loggingStatus: "loggedIn",
      };
    }
    case SIGNIN_USER_ERROR: {
      return {
        ...state,
        loggingMessage: action.payload,
        loggingStatus: "loggingError",
      };
    }
    case SIGNIN_RESET_STATUS: {
      return {
        ...state,
        loggingMessage: null,
        loggingStatus: null,
      };
    }
    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }
    case GET_LOGGED_USER_DATA: {
      return {
        ...state,
        // authUser: action.payload
      };
    }
    case HIDE_INSCRIBETE_HIGHLIGHT: {
      return {
        ...state,
        showInscribeteHighlight: false,
      };
    }
    case SHOW_INSCRIBETE_HIGHLIGHT: {
      return {
        ...state,
        showInscribeteHighlight: true,
      };
    }
    case GET_LOGGED_USER_DATA_SUCCESS: {
      const appliedOffers: any = [];
      const data = action.payload;
      if (data.candidato && data.candidato.valoraciones) {
        data.candidato.valoraciones.forEach((item: any) => {
          appliedOffers.push(item);
        });
      }
      const showInscribeteHighlight =
        data.candidato && data.candidato.numValoraciones === 0;
      return {
        ...state,
        authUser: action.payload,
        appliedOffers: appliedOffers,
        showInscribeteHighlight,
      };
    }
    case GET_LOGGED_USER_DATA_ERROR: {
      return {
        ...state,
        authUser: null,
        appliedOffers: [],
        loggingMessage: action.payload,
      };
    }
    case APPLY_OFFER:
      const appliedOffer = {
        contratoId: action.payload.id,
        contratoTipo: action.payload.tipo,
        contratoTitulo: action.payload.titulo,
        publicado: "Si",
      };
      return {
        ...state,
        appliedOffers: [...state.appliedOffers, appliedOffer],
      };
    case APPLY_OFFER_SUCCESS:
      // Actualizacion final
      const { valoracion, offer } = action.payload;
      const newAppliedOffers: any = [];
      // eslint-disable-next-line
      state.appliedOffers.map((item: any) => {
        if (item.contratoId === offer.id) {
          item.id = valoracion.id;
          item.createdAt = valoracion.createdAt;
          item.updatedAt = valoracion.updatedAt;
          newAppliedOffers.push(item);
        } else {
          newAppliedOffers.push(item);
        }
      });
      return {
        ...state,
        appliedOffers: newAppliedOffers,
      };
    case UNAPPLY_OFFER:
      const { offerId } = action.payload;
      return {
        ...state,
        appliedOffers: state.appliedOffers.filter((item: any) => {
          return item.contratoId !== offerId;
        }),
      };
    case DEFER_APPLY:
      // Defer only one apply
      return {
        ...state,
        deferredApplies: [action.payload],
      };

    default:
      return state;
  }
};

export default authReducer;
